import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  Input,
  Table,
  Form,
  TextArea,
  Icon,
  Modal,
  Header,
  Divider,
  Dropdown,
  Segment,
  Label,
  Tab,
  Message,
} from 'semantic-ui-react';

import PageTitle, { Container } from "layouts/AuthLayout/PageTitle"

/* 
// Styled Components
const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 50px auto;
`;
 */

const StyledForm = styled(Form)`
  margin-bottom: 20px;
`;

const IconButton = styled(Button)`
  padding: 8px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  color: #d9534f !important;
`;

const EditButton = styled(Button)`
  padding: 8px !important;
  background-color: transparent !important;
  box-shadow: none !important;
`;

const PhaseContainer = styled(Segment)`
  margin-bottom: 20px !important;
`;

const factorsList = [
  { key: 'factor1', text: 'Factor 1', value: 'factor1' },
  { key: 'factor2', text: 'Factor 2', value: 'factor2' },
  { key: 'factor3', text: 'Factor 3', value: 'factor3' },
  // Add more factors as needed
];

// ListItemsInput Component
const ListItemsInput = ({ label, items, setItems }) => {
  const [inputValue, setInputValue] = useState('');

  const handleAddItem = () => {
    if (inputValue.trim() !== '') {
      setItems([...items, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  return (
    <Form.Field>
      <label>{label}</label>
      <Input
        placeholder={`Add ${label}`}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        action={{
          icon: 'add',
          onClick: handleAddItem,
        }}
      />
      {items.length > 0 && (
        <Segment>
          {items.map((item, index) => (
            <Label key={index} style={{ marginBottom: '5px' }}>
              {item}
              <Icon name="delete" onClick={() => handleRemoveItem(index)} />
            </Label>
          ))}
        </Segment>
      )}
    </Form.Field>
  );
};

// ResourceModal Component remains unchanged
const ResourceModal = ({
  open,
  onClose,
  resources,
  addResource,
  removeResource,
  updateResourceInfo,
}) => {
  const [newResourceText, setNewResourceText] = useState('');
  const [newResourceUrl, setNewResourceUrl] = useState('');

  const handleAddResource = () => {
    if (newResourceText.trim() !== '') {
      addResource({ text: newResourceText, url: newResourceUrl });
      setNewResourceText('');
      setNewResourceUrl('');
    }
  };

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Header icon="linkify" content="Manage Resources" />
      <Modal.Content scrolling>
        <Form>
          {resources.map((resource, index) => (
            <Segment key={index}>
              <Form.Field>
                <label>Resource Text</label>
                <Input
                  placeholder="Resource Text"
                  value={resource.text}
                  onChange={(e) =>
                    updateResourceInfo(index, 'text', e.target.value)
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Resource URL</label>
                <Input
                  placeholder="Resource URL"
                  value={resource.url}
                  onChange={(e) =>
                    updateResourceInfo(index, 'url', e.target.value)
                  }
                />
              </Form.Field>
              <Button
                icon="trash"
                content="Remove"
                color="red"
                onClick={() => removeResource(index)}
              />
            </Segment>
          ))}
          <Divider />
          <Header as="h4">Add New Resource</Header>
          <Form.Field>
            <label>Resource Text</label>
            <Input
              placeholder="New Resource Text"
              value={newResourceText}
              onChange={(e) => setNewResourceText(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Resource URL</label>
            <Input
              placeholder="New Resource URL"
              value={newResourceUrl}
              onChange={(e) => setNewResourceUrl(e.target.value)}
            />
          </Form.Field>
          <Button
            icon="add"
            content="Add Resource"
            color="green"
            onClick={handleAddResource}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Done</Button>
      </Modal.Actions>
    </Modal>
  );
};

const Checklist = () => {
  const initialState = {
    title: '',
    description: '',
    phases: [],
    associations: [],
    context: {
      whyRecommendation: '',
      focusAreas: '',
      recommendationQuestion: '',
      organizationQuestions: [],
      effortRequires: [],
      involvedAreas: '',
      desiredOutcome: '',
      timeframe: '',
      cost: '',
      policyChange: '',
      cultureChange: '',
      dataCollection: '',
      communityConnections: '',
      primaryStakeholder: '',
    },
  };

  const [checklist, setChecklist] = useState(initialState);

  // Existing state variables...
  const [modalOpen, setModalOpen] = useState(false);
  const [currentResources, setCurrentResources] = useState([]);
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState(null);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(null);

  // State for managing the Association Modal
  const [associationModalOpen, setAssociationModalOpen] = useState(false);
  const [associationToEdit, setAssociationToEdit] = useState(null);

  // Functions to manage associations
  const openAssociationModal = (association = null) => {
    setAssociationToEdit(association);
    setAssociationModalOpen(true);
  };

  const closeAssociationModal = () => {
    setAssociationModalOpen(false);
    setNewAssociationFactor('');
    setNewAssociationPriority('');
    setAssociationSummary('');
    setAssociationMoreInfo([]);
    setAssociationToEdit(null);
  };

  // New state variables for associations
  const [newAssociationFactor, setNewAssociationFactor] = useState('');
  const [newAssociationPriority, setNewAssociationPriority] = useState('');
  const [associationSummary, setAssociationSummary] = useState('');
  const [associationMoreInfo, setAssociationMoreInfo] = useState([]);

  const handleSaveAssociation = () => {
    if (newAssociationFactor && newAssociationPriority) {
      const updatedAssociation = {
        factor: newAssociationFactor,
        priority: newAssociationPriority,
        summaryText: associationSummary,
        moreInfo: associationMoreInfo,
      };

      if (associationToEdit) {
        // Editing existing association
        const updatedAssociations = checklist.associations.map((assoc) =>
          assoc === associationToEdit ? updatedAssociation : assoc
        );
        setChecklist({ ...checklist, associations: updatedAssociations });
      } else {
        // Adding new association
        setChecklist({
          ...checklist,
          associations: [...checklist.associations, updatedAssociation],
        });
      }
      closeAssociationModal();
    }
  };

  const removeAssociation = (index) => {
    const updatedAssociations = checklist.associations.filter((_, i) => i !== index);
    setChecklist({ ...checklist, associations: updatedAssociations });
  };

  const getFactorTextByValue = (value) => {
    const factor = factorsList.find((f) => f.value === value);
    return factor ? factor.text : value;
  };

  // Existing functions remain unchanged...
  const addPhase = () => {
    setChecklist({
      ...checklist,
      phases: [
        ...checklist.phases,
        {
          phaseTitle: '',
          tasks: [{ task: '', metric: '', resources: [] }],
        },
      ],
    });
  };

  const removePhase = (index) => {
    const updatedPhases = checklist.phases.filter((_, i) => i !== index);
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  const addTask = (phaseIndex) => {
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].tasks.push({ task: '', metric: '', resources: [] });
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  const removeTask = (phaseIndex, taskIndex) => {
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].tasks = updatedPhases[phaseIndex].tasks.filter(
      (_, i) => i !== taskIndex
    );
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  const openResourceModal = (phaseIndex, taskIndex, resources) => {
    setCurrentPhaseIndex(phaseIndex);
    setCurrentTaskIndex(taskIndex);
    setCurrentResources(resources);
    setModalOpen(true);
  };

  const addResource = (newResource) => {
    const updatedResources = [...currentResources, newResource];
    const updatedPhases = [...checklist.phases];
    updatedPhases[currentPhaseIndex].tasks[currentTaskIndex].resources = updatedResources;
    setChecklist({ ...checklist, phases: updatedPhases });
    setCurrentResources(updatedResources);
  };

  const removeResource = (resourceIndex) => {
    const updatedResources = currentResources.filter((_, i) => i !== resourceIndex);
    const updatedPhases = [...checklist.phases];
    updatedPhases[currentPhaseIndex].tasks[currentTaskIndex].resources = updatedResources;
    setChecklist({ ...checklist, phases: updatedPhases });
    setCurrentResources(updatedResources);
  };

  const updateResourceInfo = (resourceIndex, field, value) => {
    const updatedResources = [...currentResources];
    updatedResources[resourceIndex][field] = value;
    const updatedPhases = [...checklist.phases];
    updatedPhases[currentPhaseIndex].tasks[currentTaskIndex].resources = updatedResources;
    setChecklist({ ...checklist, phases: updatedPhases });
    setCurrentResources(updatedResources);
  };

  const updatePhaseTitle = (index, value) => {
    const updatedPhases = [...checklist.phases];
    updatedPhases[index].phaseTitle = value;
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  const updateTaskInfo = (phaseIndex, taskIndex, field, value) => {
    const updatedPhases = [...checklist.phases];
    updatedPhases[phaseIndex].tasks[taskIndex][field] = value;
    setChecklist({ ...checklist, phases: updatedPhases });
  };

  // Tabs for Phases, Context Fields, and Associations
  const panes = [
    // Phases Tab
    {
      menuItem: 'Phases',
      render: () => (
        <Tab.Pane attached={false}>
          {/* Phases content */}
          {checklist.phases.map((phase, phaseIndex) => (
            <PhaseContainer key={phaseIndex}>
              <Form>
                <Form.Field>
                  <label>Phase Title</label>
                  <Input
                    fluid
                    placeholder="Phase Title"
                    value={phase.phaseTitle}
                    onChange={(e) => updatePhaseTitle(phaseIndex, e.target.value)}
                  />
                </Form.Field>
              </Form>
              <Table celled structured>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={6}>Task</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Metric for Completion</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Resources</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {phase.tasks.map((task, taskIndex) => (
                    <Table.Row key={taskIndex}>
                      <Table.Cell>
                        <Form.TextArea
                          placeholder="Task Description"
                          value={task.task}
                          onChange={(e) =>
                            updateTaskInfo(phaseIndex, taskIndex, 'task', e.target.value)
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          placeholder="Metric"
                          value={task.metric}
                          onChange={(e) =>
                            updateTaskInfo(phaseIndex, taskIndex, 'metric', e.target.value)
                          }
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Button
                          icon="linkify"
                          basic
                          content={`Manage (${task.resources.length})`}
                          onClick={() =>
                            openResourceModal(phaseIndex, taskIndex, task.resources)
                          }
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <IconButton
                          icon="trash alternate"
                          onClick={() => removeTask(phaseIndex, taskIndex)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Button icon labelPosition="left" primary onClick={() => addTask(phaseIndex)}>
                <Icon name="plus" />
                Add Task
              </Button>
              <Button
                icon
                labelPosition="left"
                color="red"
                onClick={() => removePhase(phaseIndex)}
                style={{ marginLeft: '10px' }}
              >
                <Icon name="trash alternate" />
                Remove Phase
              </Button>
            </PhaseContainer>
          ))}
          <Button icon labelPosition="left" primary onClick={addPhase}>
            <Icon name="plus" />
            Add New Phase
          </Button>
        </Tab.Pane>
      ),
    },
    // Context Fields Tab
    {
      menuItem: 'Context Fields',
      render: () => (
        <Tab.Pane attached={false}>
          <Form>
            <Form.Field>
              <label>Why this recommendation?</label>
              <TextArea
                placeholder="Why this recommendation?"
                value={checklist.context.whyRecommendation}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      whyRecommendation: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Focus Areas</label>
              <TextArea
                placeholder="Focus Areas"
                value={checklist.context.focusAreas}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      focusAreas: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>This recommendation aims to answer the question</label>
              <TextArea
                placeholder="This recommendation aims to answer the question"
                value={checklist.context.recommendationQuestion}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      recommendationQuestion: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <ListItemsInput
              label="Questions that we will need to answer about the organization"
              items={checklist.context.organizationQuestions}
              setItems={(newItems) =>
                setChecklist({
                  ...checklist,
                  context: {
                    ...checklist.context,
                    organizationQuestions: newItems,
                  },
                })
              }
            />
            <ListItemsInput
              label="This effort will require us to"
              items={checklist.context.effortRequires}
              setItems={(newItems) =>
                setChecklist({
                  ...checklist,
                  context: {
                    ...checklist.context,
                    effortRequires: newItems,
                  },
                })
              }
            />
            <Form.Field>
              <label>Areas of the organization that will need to be involved</label>
              <TextArea
                placeholder="Areas of the organization that will need to be involved"
                value={checklist.context.involvedAreas}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      involvedAreas: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Desired outcome</label>
              <TextArea
                placeholder="Desired outcome"
                value={checklist.context.desiredOutcome}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      desiredOutcome: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Timeframe</label>
                <Input
                  placeholder="Timeframe"
                  value={checklist.context.timeframe}
                  onChange={(e) =>
                    setChecklist({
                      ...checklist,
                      context: {
                        ...checklist.context,
                        timeframe: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Cost</label>
                <Input
                  placeholder="Cost"
                  value={checklist.context.cost}
                  onChange={(e) =>
                    setChecklist({
                      ...checklist,
                      context: {
                        ...checklist.context,
                        cost: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Policy change</label>
                <Input
                  placeholder="Policy change"
                  value={checklist.context.policyChange}
                  onChange={(e) =>
                    setChecklist({
                      ...checklist,
                      context: {
                        ...checklist.context,
                        policyChange: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Culture change</label>
                <Input
                  placeholder="Culture change"
                  value={checklist.context.cultureChange}
                  onChange={(e) =>
                    setChecklist({
                      ...checklist,
                      context: {
                        ...checklist.context,
                        cultureChange: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Collection of data</label>
              <Input
                placeholder="Collection of data"
                value={checklist.context.dataCollection}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      dataCollection: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Community connections</label>
              <Input
                placeholder="Community connections"
                value={checklist.context.communityConnections}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      communityConnections: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Primary stakeholder</label>
              <Input
                placeholder="Primary stakeholder"
                value={checklist.context.primaryStakeholder}
                onChange={(e) =>
                  setChecklist({
                    ...checklist,
                    context: {
                      ...checklist.context,
                      primaryStakeholder: e.target.value,
                    },
                  })
                }
              />
            </Form.Field>
          </Form>
        </Tab.Pane>
      ),
    },
    // Associations Tab (updated)
    {
      menuItem: 'Associations',
      render: () => (
        <Tab.Pane attached={false}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Factor</Table.HeaderCell>
                <Table.HeaderCell>Priority</Table.HeaderCell>
                <Table.HeaderCell>Summary</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {checklist.associations.map((association, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{getFactorTextByValue(association.factor)}</Table.Cell>
                  <Table.Cell>
                    <Label color={association.priority === 'primary' ? 'blue' : 'grey'}>
                      {association.priority.charAt(0).toUpperCase() + association.priority.slice(1)}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>{association.summaryText}</Table.Cell>
                  <Table.Cell>
                    <EditButton
                      icon="edit"
                      title="Edit Association"
                      onClick={() => openAssociationModal(association)}
                    />
                    <IconButton
                      icon="trash alternate"
                      onClick={() => removeAssociation(index)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Button icon labelPosition="left" primary onClick={() => openAssociationModal()}>
            <Icon name="plus" />
            Add Association
          </Button>

          {/* Add/Edit Association Modal */}
          <Modal open={associationModalOpen} onClose={closeAssociationModal}>
            <Modal.Header>
              {associationToEdit ? 'Edit Association' : 'Add Association'}
            </Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Field>
                  <label>Factor</label>
                  <Dropdown
                    placeholder="Select Factor"
                    fluid
                    selection
                    options={factorsList}
                    value={newAssociationFactor}
                    onChange={(e, { value }) => setNewAssociationFactor(value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Priority</label>
                  <Dropdown
                    placeholder="Select Priority"
                    fluid
                    selection
                    options={[
                      { key: 'primary', text: 'Primary', value: 'primary' },
                      { key: 'secondary', text: 'Secondary', value: 'secondary' },
                    ]}
                    value={newAssociationPriority}
                    onChange={(e, { value }) => setNewAssociationPriority(value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Summary Text</label>
                  <TextArea
                    placeholder="Enter summary..."
                    value={associationSummary}
                    onChange={(e) => setAssociationSummary(e.target.value)}
                  />
                </Form.Field>
                <ListItemsInput
                  label="More Info"
                  items={associationMoreInfo}
                  setItems={setAssociationMoreInfo}
                />
              </Form>
              {(!newAssociationFactor || !newAssociationPriority) && (
                <Message warning>
                  <Message.Header>Incomplete Information</Message.Header>
                  <p>Please fill out all required fields to save the association.</p>
                </Message>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={closeAssociationModal}>Cancel</Button>
              <Button
                primary
                onClick={handleSaveAssociation}
                disabled={!newAssociationFactor || !newAssociationPriority}
              >
                {associationToEdit ? 'Save Changes' : 'Add Association'}
              </Button>
            </Modal.Actions>
          </Modal>
        </Tab.Pane>
      ),
    },
  ];

  // When editing an association, pre-fill the form fields
  useEffect(() => {
    if (associationToEdit) {
      setNewAssociationFactor(associationToEdit.factor);
      setNewAssociationPriority(associationToEdit.priority);
      setAssociationSummary(associationToEdit.summaryText || '');
      setAssociationMoreInfo(associationToEdit.moreInfo || []);
    } else {
      // Clear fields when adding new association
      setNewAssociationFactor('');
      setNewAssociationPriority('');
      setAssociationSummary('');
      setAssociationMoreInfo([]);
    }
  }, [associationToEdit]);

  return (
    <Container>
      <PageTitle newPageTitle="New Recommendation Template" />

      <StyledForm>
        <Form.Field>
          <label>Checklist Title</label>
          <Input
            placeholder="Enter Checklist Title"
            value={checklist.title}
            onChange={(e) => setChecklist({ ...checklist, title: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <label>Checklist Description</label>
          <TextArea
            placeholder="Enter Description"
            value={checklist.description}
            onChange={(e) => setChecklist({ ...checklist, description: e.target.value })}
          />
        </Form.Field>
      </StyledForm>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />

      <ResourceModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        resources={currentResources}
        addResource={addResource}
        removeResource={removeResource}
        updateResourceInfo={updateResourceInfo}
      />
    </Container>
  );
};

export default Checklist;