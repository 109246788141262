import React from "react";
import { Routes, Route } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";


import List from "./Model/List";
import Create from "./GptPromptBuild";
import GptMembers from "./Members/List";
import Summary from "./ResponseScore/List";
import ScoreDetails from "./ResponseScore/Details";

import Details from "./GptDetails";

import PageTitle, { Container, SectionDiv } from "layouts/AuthLayout/PageTitle"

function GptHome() {
  const navigate = useNavigate()

  return (
    <Container>
      <PageTitle
        newPageTitle="ChatGPT Prompts"
        newPageSubtitle="Manage details of GPT prompts and score"
      />

      <SectionDiv>
        <Button inverted color="purple" onClick={() => navigate("/app/gpt")}>
          Models
        </Button>
        <Button inverted color="purple" onClick={() => navigate("/app/gpt/members")}>
          Member
        </Button>
        <Button inverted color="purple" onClick={() => navigate("/app/gpt/create")}>
          Survey AI
        </Button>
        <Button inverted color="blue" onClick={() => navigate("/app/gpt/summary")}>
          My Tasks
        </Button>
      </SectionDiv>

      <Routes>
        <Route path="/" element={<List />} />
        <Route path="/members" element={<GptMembers />} />
        <Route path="/create" element={<Create />} />
        <Route path="details/:id" element={<Create />} />
        <Route path="/summary" element={<Summary />} />
        <Route path="/score/:id" element={<ScoreDetails />} />
        <Route path="/task" element={<Details />} />

      </Routes>
    </Container>
  );
}

export default GptHome;
