import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Dimmer, Loader, Grid, Pagination, Button, Header, Table } from 'semantic-ui-react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import PageTitle, { Container, SectionDiv } from "layouts/AuthLayout/PageTitle"

import Select from "react-select";

import { CATEGORY_COMPLIANCE_HEADERS } from "data/AdminData"

import {
    UPDATE_SURVEY_STRUCTURE_COMPLIANCE_PAGE_NUMBER,
    SG_GET_SURVEY_STRUCTURE_COMPLIANCES,
} from "constants/actions";

import { format } from 'date-fns'

const TemplateList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    let ReloadPage = searchParams.get("reload");

    const [surveyTypeSelected, setSurveyTypeSelected] = useState(CATEGORY_COMPLIANCE_HEADERS[0])

    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = useState(1)

    const { get_category_compliance,
        get_PageNumber,
        get_category_updated } = useSelector(
            (state) => ({
                get_category_compliance: state.surveystructurecompliance.survey_structure_compliance,
                get_PageNumber: state.surveystructurecompliance.pageNumber,
                get_category_updated: state.surveystructurecompliance
            }),
            shallowEqual
        );

    useEffect(() => {
        dispatch({
            type: SG_GET_SURVEY_STRUCTURE_COMPLIANCES,
            payload: `status=1&detail_level=1${page ? `&page=${page}` : ''}${surveyTypeSelected?.value ? `&priority=${surveyTypeSelected?.value}` : ''}`,
        });
    }, [dispatch, page, surveyTypeSelected]);

    useEffect(() => {
        setPage(get_PageNumber)
    }, [get_PageNumber]);

    useEffect(() => {
        setPage(1);
    }, [surveyTypeSelected]);

    useEffect(() => {
        setResults(get_category_compliance?.results);
        setTotalPages(Math.ceil(get_category_compliance?.count / get_category_compliance?.page_size))
        if (get_category_compliance?.count % get_category_compliance?.page_size) {
            setTotalPages(Math.ceil(get_category_compliance?.count / get_category_compliance?.page_size))
        }

    }, [get_category_compliance, page, ReloadPage]);

    useEffect(() => {
        if (get_category_updated?.created || get_category_updated?.deleted) {
            dispatch({
                type: SG_GET_SURVEY_STRUCTURE_COMPLIANCES,
                payload: "detail_level=1&page=1"
            });
        }
    }, [dispatch, get_category_updated]);

    function handlePageChange(_, value) {
        setPage(value.activePage);
        dispatch({
            type: UPDATE_SURVEY_STRUCTURE_COMPLIANCE_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    return (
        <Container>
            <PageTitle newPageTitle="Categories" />
            <SectionDiv>
                <Grid>
                    <Grid.Row>
                        <Grid.Column floated="left" width={13}>
                            <Header.Subheader>
                                Create and manage organization and demographic categories for data segmentation
                            </Header.Subheader>
                        </Grid.Column>

                        <Grid.Column width={3}>
                            <Select
                                name="survey_preference"
                                options={CATEGORY_COMPLIANCE_HEADERS}
                                isClearable
                                onChange={setSurveyTypeSelected}
                                value={surveyTypeSelected}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </SectionDiv>

            <Dimmer>
                <Loader />
            </Dimmer>

            <SectionDiv>
                <Table selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Priority</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Created On</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Last Updated</Table.HeaderCell>
                            <Table.HeaderCell width={1}>ID</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {results && results.map((result, i) => {
                            return (<Table.Row key={i} onClick={() => navigate(`/app/category-compliance/details/${result?.id}`)} style={{ cursor: 'pointer' }}>
                                <Table.Cell>{result?.name}</Table.Cell>
                                <Table.Cell>{CATEGORY_COMPLIANCE_HEADERS.filter(cc => cc.value === result?.priority)?.[0]?.label}</Table.Cell>
                                <Table.Cell>{result?.created_at && format(new Date(result?.created_at), 'MMM. d, yyyy')}</Table.Cell>
                                <Table.Cell>{result?.updated_at && format(new Date(result?.updated_at), 'MMM. d, yyyy')}</Table.Cell>
                                <Table.Cell>{result?.id}</Table.Cell>
                            </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>

            </SectionDiv>
            <Grid>
                <Grid.Row>
                    <Grid.Column floated="left" width={7}>
                        <Pagination
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            activePage={page}
                            totalPages={totalPages ? totalPages : 0}
                            onPageChange={handlePageChange}
                        />
                    </Grid.Column>
                    <Grid.Column floated="right" textAlign="right" width={8}>
                        <Button primary
                            floated='right'
                            onClick={() => navigate(`/app/category-compliance/create/`)}>
                            Add New
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default TemplateList;
