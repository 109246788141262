import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_SURVEY_QUESTION,
  SG_EDIT_SURVEY_QUESTION,
} from "constants/actions";

import ReactJson from "react-json-view";
import { Icon, Button, Header, List, Modal, Card } from "semantic-ui-react";
import { format } from "date-fns";

import { SURVEY_TYPE, STATUS } from "data/AdminData";

const arrange_Questions = (questions) => {
  if (questions?.dimension) {
    return questions.dimension
      .map((item, i) => {
        return item.factors.map((factor, f) => {
          return factor.questions.map((q, idx) => {
            return {
              question: q.q,
              dimension: i,
              factor: f,
              q: idx,
              role: q.role,
              reverse: q.reverse,
              order: q.id,
            };
          });
        });
      })
      .flat()
      .flat()
      .sort((a, b) => a.order - b.order);
  }
};

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [questionId, setQuestionId] = useState(false);
  const [questionDetails, setQuestionDetails] = useState(null);
  const [questionJson, setQuestionJson] = useState({});
  const [askComments, setAskComments] = useState([]);

  const [surveyQuestion, setSurveyQuestion] = useState([]);
  const [validationSummary, setValidationSummary] = useState("");
  const [isValid, setIsValid] = useState("");

  /*   const [updated, setUpdated] = useState(false);
    const [openSummary, setOpenSummary] = useState(false);
    const [openComment, setOpenComment] = useState(false);
    const [openCreateNew, setOpenCreateNew] = useState(false);
   */

  function BackArrow() {
    navigate(`/app/questions`);
  }

  useEffect(() => {
    setQuestionId(id);
  }, [id]);

  useEffect(() => {
    if (questionId) {
      dispatch({
        type: SG_FETCH_SURVEY_QUESTION,
        payload: { id: questionId },
      });
    }
  }, [dispatch, questionId]);

  const { get_questions } = useSelector(
    (state) => ({
      get_questions: state.surveyquestion,
    }),
    shallowEqual
  );

  useEffect(() => {
    setSurveyQuestion(
      arrange_Questions(get_questions?.[questionId]?.questions)
    );
    setAskComments(get_questions?.[questionId]?.questions?.comments || []);
  }, [get_questions, questionId]);

  useEffect(() => {
    setQuestionDetails(get_questions?.[questionId]);
  }, [get_questions, questionId]);

  useEffect(() => {
    setQuestionJson(
      questionDetails?.questions)
  }, [questionDetails?.questions])

  function UpdateComments() {
    const _questions = questionDetails.questions;
    _questions.comments = askComments;

    dispatch({
      type: SG_EDIT_SURVEY_QUESTION,
      payload: {
        id: questionId,
        questions: _questions,
      },
    });
  }

  function EditQuestions() {
    navigate(`/app/questions/edit/${questionId}`);
  }

  //   create json from scratch
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");

    // this should show what was pasted for user to edit
    setQuestionJson(pastedData);
    // setJsonValue(pastedData);
    validateJson(pastedData);
  };

  const validateJson = (data) => {
    setQuestionJson(data);
    try {
      JSON.parse(data);
      setValidationSummary("JSON is valid!");
      setIsValid(true);
    } catch (error) {
      setIsValid(false);
      console.error("Error parsing pasted JSON:", error);
      setValidationSummary(`JSON is not valid: ${error.message}`);
    }
  };


  return (
    <>
      <HeaderDiv>
        <Header as="h2">
          <Icon
            style={{ cursor: "pointer" }}
            onClick={BackArrow}
            name="arrow left"
          />
          {questionDetails?.name}
        </Header>
      </HeaderDiv>
      <Card fluid>
        <Card.Content>
          <Container>
            <List horizontal>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <ListHeader>Survey Type</ListHeader>
                    {questionDetails?.survey_type &&
                      SURVEY_TYPE.filter(
                        (sy) => sy.value === questionDetails?.survey_type
                      )?.[0]?.label}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <ListHeader>Status</ListHeader>
                    {questionDetails?.status &&
                      STATUS.filter(
                        (sy) => sy.value === questionDetails?.status
                      )?.[0]?.label}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <ListHeader>URL Location</ListHeader>
                    {questionDetails?.url_location}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <ListHeader>Created On</ListHeader>
                    {questionDetails?.created_at &&
                      format(
                        new Date(questionDetails?.created_at),
                        "MMM. d, yyyy"
                      )}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <ListHeader>Last Updated</ListHeader>
                    {questionDetails?.updated_at &&
                      format(
                        new Date(questionDetails?.updated_at),
                        "MMM. d, yyyy"
                      )}
                  </ListContainer>
                </List.Content>
              </List.Item>
            </List>

            <List horizontal>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <ListHeader>ID</ListHeader>
                    <List.Description>{questionDetails?.sort_order}</List.Description>
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <Button color="green" onClick={EditQuestions}>
                      Edit Survey Template
                    </Button>
                  </ListContainer>
                </List.Content>
              </List.Item>

            </List>
          </Container>
        </Card.Content>
      </Card>


      {/* {surveyQuestion?.length > 0 && */}
      <Card fluid>
        <Card.Content>
          <ReactJson src={questionJson} />
        </Card.Content>
      </Card>

      {/*
      Todo: TP IT doesn't look like the ability to add new questions to a survey or to review and edit them
      seems to work in any way other than using the JSON directly. Some of this sort of works, but it needs
      a lot of love.
      
      <SectionDiv lastSection={true}></SectionDiv>

      <Modal open={openSummary} onClose={() => setOpenSummary(false)}>
        <Modal.Header>
          <Header as="h1">
            Survey Question {surveyQuestion?.length}
            <Header.Subheader>Survey Question List</Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <SectionDiv>
              <pre>{JSON.stringify(surveyQuestion, null, 3)}</pre>
            </SectionDiv>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenSummary(false)} color="violet">
            Close <Icon name="close" />
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal open={openComment} onClose={() => setOpenComment(false)}>
        <Modal.Header>
          <Header as="h1">
            Survey Comment Question {askComments?.length}
            <Header.Subheader>Survey Comment Question</Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <SectionDiv>
              <pre>{JSON.stringify(askComments, null, 3)}</pre>
            </SectionDiv>
          </Modal.Description>
          <AddEditComment
            totalQuestions={surveyQuestion?.length + askComments?.length}
            askComments={askComments}
            setAskComments={setAskComments}
            updated={updated}
            setUpdated={setUpdated}
            UpdateComments={UpdateComments}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenComment(false)} color="violet">
            Save &amp; close <Icon name="close" />
          </Button>
        </Modal.Actions>
      </Modal>

<Modal open={openCreateNew} onClose={() => setOpenCreateNew(false)}>
  <Modal.Header>
    <Header as="h1">
      Add or Update the entire Question
      <Header.Subheader></Header.Subheader>
    </Header>
  </Modal.Header>
  <Modal.Content scrolling>
    <Modal.Description>
      <textarea
        placeholder="Paste your JSON here..."
        value={JSON.stringify(questionJson, null,
          2)}
        onPaste={handlePaste}
        onChange={(e) => validateJson(e.target.value)}
        style={{ width: "100%", height: "100%", minHeight: "400px" }} // Set styles for full screen
      />

      {validationSummary && (
        <div style={{ color: isValid ? "green" : "red" }}>
          {validationSummary}
        </div>
      )}
    </Modal.Description>
    <AddEditComment
      totalQuestions={surveyQuestion?.length + askComments?.length}
      askComments={askComments}
      setAskComments={setAskComments}
      updated={updated}
      setUpdated={setUpdated}
      UpdateComments={UpdateComments}
    />
  </Modal.Content>
  <Modal.Actions>
    <Button onClick={() => setOpenComment(false)} color="violet">
      Save &amp; close <Icon name="close" />
    </Button>
  </Modal.Actions>
</Modal> */}
    </>
  );
};

export default Details;

const Container = styled.div`
  display: flex;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
`;

const HeaderDiv = styled.div`
  margin-top: 50px;
`

const ListHeader = styled(List.Header)`
  padding-bottom: 5px;
`