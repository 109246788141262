import React from "react";
import { Routes, Route } from "react-router-dom";
import List from "./List";
import Details from "./AddEdit";

import PageTitle, { Container } from "layouts/AuthLayout/PageTitle"

function ActivityLog() {
  return (
    <Container>
      <PageTitle 
        newPageTitle="Activity Log" 
        newPageSubtitle="Audit background task processing"
      />

      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
      </Routes>
    </Container>
  );
}

export default ActivityLog;
