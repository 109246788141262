import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Message, Divider, Grid, Segment, Dimmer, Loader, Card, Pagination, } from 'semantic-ui-react'

// import CategoryCard from "./CategoryCard";
import CopyCategory from "./CopyCategory";
import CreateCategory from "categories/List/CreateCategory";
import RowCard from "./RowCard";

import OrganizationSelect from "organizations/OrganizationSelect";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  UPDATE_SURVEY_STRUCTURE_PAGE_NUMBER,
  SG_GET_SURVEY_STRUCTURES,
  CLEAR_SURVEY_STRUCTURE,
} from "constants/actions";

const Categories = ({ setOrgName }) => {
  const dispatch = useDispatch();

  const [organizationId, setOrganizationId] = useState(false)

  const [results, setResults] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [resetPageLoad, setResetPageLoad] = useState(false)

  const { 
    get_survey_structure,
    get_survey_structure_copied,
    get_selectedOrg } = useSelector(
      (state) => ({
        get_survey_structure: state.surveystructure.survey_structure,
        get_survey_structure_copied: state.surveystructure.survey_structure_copied,
        get_selectedOrg: state.selectedOrg,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
    } else {
      dispatch({
        type: CLEAR_SURVEY_STRUCTURE,
      });
    }
  }, [dispatch, organizationId, resetPageLoad]);

  useEffect(() => {
    setOrgName(false)
  }, [setOrgName]);


  useEffect(() => {
    setOrganizationId(get_selectedOrg?.organization?.id)
  }, [get_selectedOrg]);

  useEffect(() => {
    setLoading(true)
    setResults([])
    setPage(1)
    setTotalPages(1)
    setPage(get_survey_structure?.pageNumber)
    if (get_survey_structure?.results) {
      setResults(get_survey_structure?.results);
      if (get_survey_structure?.count) {
        setTotalPages(Math.ceil(get_survey_structure?.count / get_survey_structure?.page_size))
        if (get_survey_structure?.count % get_survey_structure?.page_size) {
          setTotalPages(Math.ceil(get_survey_structure?.count / get_survey_structure?.page_size))
        }
      }
    }

  }, [get_survey_structure, page, resetPageLoad, organizationId]);

  useEffect(() => {
    if (get_survey_structure_copied?.new_survey_structure_id > 0) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
      });
    }
  }, [get_survey_structure_copied, dispatch]);


  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_SURVEY_STRUCTURE_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    if (loading) {
      const timer1 = setTimeout(() => {
        setLoading(false)
      }, 2000)
      return () => clearTimeout(timer1)
    }
  }, [loading]);

  return (
    <Container>
      <Message
        color='orange'
        icon='warning sign'
        header='Do not modify before you read this'
        content={
          `This data is crucial for the entire survey, please 
          do not change or add anything to this page without 
          consulting support team. Any errors would appear below this message. 
          Please note even if there are no errors things may break if you
          change this file without proper knowledge. To modify please contact
          support.
        `}
      />
      <Segment placeholder>
        <Grid columns={2} relaxed='very' stackable>
          <Grid.Column>
            <CenteredObject>
              <CopyCategory
                organizationId={organizationId}
                setOrganizationId={setOrganizationId}
                trigger={<StyledButton>Copy Category Settings</StyledButton>}
              />

            </CenteredObject>
            <CenteredObject>
              <label>Copy category settings from the current organzation to another.</label>
            </CenteredObject>
          </Grid.Column>

          <Grid.Column verticalAlign='middle'>
            <CenteredObject>
              <CreateCategory
                organizationId={organizationId}
                setOrganizationId={setOrganizationId}
                trigger={<StyledButton>Create Organization Categories</StyledButton>}
              />

            </CenteredObject>
            <CenteredObject>
              <label>Once your created you can use JSON data to update</label>
            </CenteredObject>

          </Grid.Column>
        </Grid>
        <Divider vertical>Or</Divider>
      </Segment>

      <OrganizationSelect
        setResetPageLoad={setResetPageLoad} />

      {organizationId ?
        <>
          <Segment id="results" basic>
            <Dimmer active={loading}>
              <Loader />
            </Dimmer>
            <Card.Group>
              {results && results.length > 0 ? results.map((result) => (
                <RowCard row={result} key={result?.id} />
              )) :

                <Message
                  color="red"
                  icon="warning sign"
                  header="No Survey Structure Found"
                  content={`No Survey Structure found for this organizations`} />
              }
            </Card.Group>
          </Segment>
          <SectionDiv>

            <Pagination
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              activePage={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </SectionDiv>
        </>
        :
        <Message
          color="orange"
          icon="warning sign"
          header="No Organization Selected"
          content={`Please select an organization to view the list of files
    `}
        />}

    </Container>
  );
};

export default Categories;

const Container = styled.div`
  margin-bottom: 30px;
`;

const CenteredObject = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  margin-top: 30px;
  padding: 0px 30px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`

