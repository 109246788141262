import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router";

import { useNavigate } from "react-router-dom";
import { Header, Icon } from "semantic-ui-react";


import {
  SG_FETCH_REPORT_TEMPLATE,
} from "constants/actions";

import DataForm from "./DataForm"

const Matcher = ({ newResponse, setNewResponse }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [reportTemplate, setReportTemplate] = useState(false);

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  function BackArrow() {
    navigate(`/app/report-templates/`);
  }

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_REPORT_TEMPLATE,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId,]);

  const { get_report_template } = useSelector(
    (state) => ({
      get_report_template: state.report_template,
    }),
    shallowEqual
  );

  useEffect(() => {
    setReportTemplate(get_report_template?.[templateId])
  }, [templateId, get_report_template])

  return (
  <>
      <HeaderContainer>
        <HeaderText as="h2" onClick={BackArrow}>
          <Icon
            style={{ cursor: "pointer" }}
            onClick={BackArrow}
            name="arrow left"
          />
          Recommendation Templates
        </HeaderText>
      </HeaderContainer>
    <Container>
      <DataForm templateId={templateId}
        reportTemplate={reportTemplate}
        newResponse={newResponse}
        setNewResponse={setNewResponse} />
    </Container>
    </>
  );
};

export default Matcher;

const Container = styled.div`
  margin-top: 50px;
  padding-left: 60px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

const HeaderText = styled(Header)`
  margin-top: 40px;
  cursor: pointer;
`;