import React, { useState, useEffect } from "react";

import TableRows from "./TableRows";
import styled from "styled-components";

import { useLocation, useNavigate } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_EMPLOYEE_RECORD_VALUE } from "constants/actions";

import { Dimmer, Grid, Button, Header, Loader, Card } from 'semantic-ui-react'

import PageTitle, { Container, SectionDiv } from "layouts/AuthLayout/PageTitle"
import OrganizationSelect from "organizations/OrganizationSelect";


const List = ({ hideOrgSelect, orgId }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const location = useLocation()

    const [organizationId, setOrganizationId] = useState(false)
    const [resetPageLoad, setResetPageLoad] = useState(false)

    const [results, setResults] = useState([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        if (orgId) {
            setOrganizationId(orgId)
        }
    }, [orgId]);

    const [searchTerm, setSearchTerm] = useState('')
    useEffect(() => {
        dispatch({
            type: SG_GET_EMPLOYEE_RECORD_VALUE,
            payload: `page=${page}${organizationId ? `&organization=${organizationId}` : ''}${searchTerm ? `&search=${searchTerm}` : ''}`,
        });

        setLoading(true)
    }, [dispatch, page, searchTerm, organizationId,
        resetPageLoad]);

    const { get_employee_record_value,
        get_selectedOrg } = useSelector(
            (state) => ({
                get_employee_record_value: state.employee_record_value,
                get_selectedOrg: state.selectedOrg,
            }),
            shallowEqual
        );

    useEffect(() => {
        setOrganizationId(get_selectedOrg?.organization?.id)
    }, [get_selectedOrg]);

    useEffect(() => {
        setResults(get_employee_record_value?.employee_record_value);
    }, [get_employee_record_value, page]);

    useEffect(() => {
        setPage(get_employee_record_value?.pageNumber);
        setTotalPages(
            Math.floor(
                get_employee_record_value?.employee_record_value?.length / 4
            ) + 1
        );
    }, [get_employee_record_value, page]);

    /*     function handlePageChange(event, value) {
            setPage(value.activePage);
            dispatch({
                type: UPDATE_EMPLOYEE_RECORD_PAGE_NUMBER,
                payload: value.activePage,
            });
        }
     */
    useEffect(() => {
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    return (
        <>
            <PageTitle newPageTitle="Employee Records" />
            <Container>
                <SectionDiv>
                    <Grid>
                        <Grid.Row columns={2} >
                            <Grid.Column floated="left" width={11}>
                                <Header.Subheader>
                                    <p>
                                        Create and manage custom data elements for an organization (e.g. salary, sick days, performance).
                                        Record data can change over time and is stored within each employee's survey to memorialize their information when they are evaluated.
                                    </p>
                                </Header.Subheader>
                            </Grid.Column>
                            <Grid.Column floated="right" width={4}>
                                {!hideOrgSelect &&
                                    <OrganizationSelect
                                        setResetPageLoad={setResetPageLoad} />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </SectionDiv>
                
                <SectionDiv>
                    <Dimmer active={loading}>
                        <Loader />
                    </Dimmer>
                    <Card.Group>
                        <TableRows rows={results} />
                    </Card.Group>
                </SectionDiv>

                <SectionDiv>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column floated="left" width={8}>
                                {/*  Todo: TP Pagination not fully implemented. I believe we need paging in the API. At
    the moment, I believe all records are always bring returned. When page size is implemented, it
    would be good to move the Add Record button to the bottom of the page.
                            <Pagination
                                ellipsisItem={null}
                                firstItem={null}
                                lastItem={null}
                                activePage={page || 1}
                                totalPages={totalPages || 1}
                                onPageChange={handlePageChange}
    />*/}
                            </Grid.Column>
                            <Grid.Column floated="right" textAlign="right" width={8}>
                                {(location.pathname !== '/app/employee-record-value/new') &&
                                    <Button primary
                                        onClick={() => navigate(`/app/employee-record-value/new`)}
                                    >New Record</Button>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </SectionDiv>
            </Container>
        </>
    );
};

export default List;

