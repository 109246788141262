import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button,  Message, Icon, Confirm, Pagination,  Dimmer,  Loader } from "semantic-ui-react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { saveAs } from "file-saver";

import {
  SG_DELETE_FILE_UPLOAD,
  UPDATE_FILE_UPLOAD_PAGE_NUMBER,
} from "constants/actions";

import format from "date-fns/format";

const UploadedList = ({
  resetPageLoad,
  setResetPageLoad,
  setShowReportId,
  fetchAgain,
  setFetchAgain,
  showErrors,
  deletedRecord,
  setDeletedRecord,
  setShowPage,
  AllPageOptions,
  get_file_uploads
}) => {
  const dispatch = useDispatch();

  const [pageLoading, setPageLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);

  const [fileUploads, setFileUploads] = useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    setPageLoading(true)
    setFileUploads(get_file_uploads?.file_uploads?.results);
    setPage(get_file_uploads?.pageNumber)
    if (get_file_uploads?.file_uploads?.count) {
      setTotalPages(Math.ceil(setTotalPages(get_file_uploads?.file_uploads?.count / get_file_uploads?.file_uploads?.page_size)))
      if (get_file_uploads?.file_uploads?.count % get_file_uploads?.file_uploads?.page_size) {
        setTotalPages(Math.ceil(get_file_uploads?.file_uploads?.count / get_file_uploads?.file_uploads?.page_size))
      }
    }
  }, [get_file_uploads, page, resetPageLoad]);

  const {
		get_file_upload_deleted,
	} = useSelector(
		(state) => ({
			get_file_uploads: state.fileupload.deleted,
		}),
		shallowEqual
	);

  useEffect(() => {
    setDeletedRecord(get_file_upload_deleted)
  }, [get_file_upload_deleted, setDeletedRecord]);

  useEffect(() => {
    if (pageLoading) {
      const timer1 = setTimeout(() => {
        setPageLoading(false)
      }, 2000)
      return () => clearTimeout(timer1)
    }
  }, [pageLoading]);

  useEffect(() => {
    if (fetchAgain || deletedRecord) {
      setPageLoading(true);
    }
  }, [fetchAgain, deletedRecord]);


  function ViewRecord(id) {
    setShowPage(AllPageOptions[1]);
    setShowReportId(id);
  }

  function clearErrors() {
    setFetchAgain(false);
    setDeletedRecord(false);
  }

  function DeleteFileUpload() {
    setResetPageLoad(deleteId)
      dispatch({
        type: SG_DELETE_FILE_UPLOAD,
        payload: { id: deleteId },
      });
    ;
    setConfirmDelete(false);
    setShowReportId(false);
    setDeleteId(false);
    setPageLoading(true)
  }

  function SelectDeleteRow(id) {
    setDeleteId(id);
    setConfirmDelete(true);
  }

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_FILE_UPLOAD_PAGE_NUMBER,
      payload: value.activePage,
    });
  }


  return (
    <>
      {!showErrors && fetchAgain > 0 && (
        <Message
          positive
          onDismiss={clearErrors}
          header="New Upload File Added"
          content="You have successfully added a new upload file."
        />
      )}

      {!showErrors && deletedRecord > 0 && (
        <Message
          negative
          onDismiss={clearErrors}
          header="Uploaded File Removed"
          content="You have successfully removed an uploaded file."
        />
      )}
      <Dimmer.Dimmable dimmed={pageLoading}>
        <Dimmer active={pageLoading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>

        <Table>
          <TR>
            <TH width={30}>Name</TH>
            <TH width={30}>Uploaded</TH>
            <TH width={25}>Status</TH>
            <TH width={25}></TH>
          </TR>

          {fileUploads &&
            fileUploads.map((brief, k) => {
              const saveFile = () => {
                saveAs(brief?.data_file, `${brief?.name}.csv`);
              };
              return (
                <TR key={k}>
                  <TD width={30}>
                    {brief?.name}
                  </TD>
                  <TD width={30}>
                    {format(
                      new Date(brief?.created_at?.toLocaleString()),
                      "EEEE, MMM. d, yyyy h:mm a"
                    )}
                    <br />
                    {brief?.time_zone}
                  </TD>
                  <TD width={25}>{brief?.status_text}</TD>
                  <TD width={25} style={{ textAlign: 'right' }}>
                    <StyledButtonGrey onClick={saveFile}>
                      <Icon name="download" /> Download
                    </StyledButtonGrey>
                      <StyledButton onClick={() => ViewRecord(brief?.id)}>
                        View
                      </StyledButton>
                      <Button icon onClick={() => SelectDeleteRow(brief?.id)}>
                        <Icon name="trash alternate" />
                      </Button>
                      <Confirm
                        open={confirmDelete}
                        onCancel={() => setConfirmDelete(false)}
                        onConfirm={() => DeleteFileUpload(brief?.id)}
                      />
                  </TD>
                </TR>
              );
            })}
        </Table>
      </Dimmer.Dimmable>

      <SectionDiv>

        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page ? page : 1}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </>
  );
};

export default UploadedList;

const Table = styled.div`
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  margin-bottom: 50px;
  font-family: "Barlow", sans-serif;
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
  margin:10px 0px;
  min-height: 110px;
  margin-bottom: ${props => props.lastRow ? "100px" : "0px"};
  ${props => props.showBorder && "border: 1px solid #dcdcdcdc"};
  ${props => props.showBorder && "padding: 15px"};

`

const TR = styled.div`
  display: flex;
  padding: 15px 10px;
  width: 100%;
`;

const TH = styled.div`
  width: ${(props) => props.width + "%"};
  font-weight: bold;
  border-bottom: 1px solid #9b9b9b;
  padding-bottom: 5px;
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  position: relative;
`;


const StyledButtonGrey = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #595959;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #595959;
    color: #595959;
  }
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;
  margin-right: 5px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;
