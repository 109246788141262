import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom';

import List from './List';
import Add from './AddEdit';
import Details from './AddEdit';

import PageTitle, { Container } from "layouts/AuthLayout/PageTitle"

function GPTRecommdations() {
  const [reload, setReload] = useState(null)

  return (
    <Container>
      <PageTitle
        newPageTitle="ChatGPT Recommendations"
        newPageSubtitle="ChatGPT recommendation email responses"
      />

      <Routes>
        <Route path="/" element={<List reload={reload} />} />
        <Route path="details/:id" element={<Details setReload={setReload} />} />
        <Route path="create" element={<Add setReload={setReload} />} />
      </Routes>
    </Container>
  );
}

export default GPTRecommdations;
