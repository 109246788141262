import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

import RowCard from "./RowCard";
import { Button } from "semantic-ui-react";

import PageTitle, { Container } from "layouts/AuthLayout/PageTitle"

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  SG_GET_REPORT_TEMPLATE,
  UPDATE_REPORT_TEMPLATE_PAGE_NUMBER,
} from "constants/actions";

import { Dimmer, Loader, Pagination, Grid } from "semantic-ui-react";

const List = ({ reload }) => {
  // employee_record_value
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //   const [resetPageLoad, setResetPageLoad] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [newResponse, setNewResponse] = useState(0)

  const [loadData, setLoadData] = useState(false);

  const { get_report_template } = useSelector(
    (state) => ({
      get_report_template: state.report_template,
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoadData(true);
    const timer1 = setTimeout(() => {
      setLoadData(get_report_template?.pending);
    }, 500);
    return () => clearTimeout(timer1);
  }, [get_report_template.pending]);



  useEffect(() => {
    // if the response is ok 200
    dispatch({
      type: SG_GET_REPORT_TEMPLATE,
      payload: `page=${page}${searchTerm ? `&search=${searchTerm}` : ""}`,
    });

    setLoading(true);
  }, [dispatch, page, searchTerm, reload]);


  useEffect(() => {
    setResults(get_report_template?.report_template?.results);
    setPage(get_report_template?.pageNumber);
    setTotalPages(
      Math.ceil(
        get_report_template?.report_template?.count /
        get_report_template?.report_template?.page_size
      )
    );
  }, [get_report_template, page]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_REPORT_TEMPLATE_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_report_template?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_report_template]);

  function GetNew() {
    setNewResponse(Math.random())
    navigate(`/app/report-templates/create/`);
  }



  return (
    <Container>
      <PageTitle
        newPageTitle="Recommendation Templates"
        newPageSubtitle="Manage global recommendation templates"
      />

      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      
      {results && <RowCard rows={results} />}

      <Grid>
        <Grid.Row>
          <Grid.Column floated="left" width={7}>
            <Pagination
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              activePage={page}
              totalPages={totalPages || 0}
              onPageChange={handlePageChange}
            />
          </Grid.Column>
          <Grid.Column floated="right" textAlign="right" width={8}>
            {location?.pathname !== "/app/report-templates/create/" &&
              <AddNewButton
                disabled={loadData}
                primary
                onClick={GetNew}
              >
                Create New
              </AddNewButton>}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default List;


const AddNewButton = styled(Button)`
  display: ${({ hide }) => (hide ? "none" : "block")};
`;
