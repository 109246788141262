import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import Details from './Details';
import AddEdit from './AddEdit';

import PageTitle, { Container } from "layouts/AuthLayout/PageTitle"

function PdfTemplates() {

  return (
    <Container>
      <PageTitle
        newPageTitle="PDF Templates"
        newPageSubtitle="Manage PDF templates for recommendation reports"
      />

      <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
        <Route path="new" element={<AddEdit />} />
        <Route path="edit/:id" element={<AddEdit />} />
      </Routes>
    </Container>
  );
}

export default PdfTemplates;
