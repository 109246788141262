import React from 'react';
import './IconWithTooltip.css'; // Import the CSS file
import { Icon } from 'semantic-ui-react'

const IconHelpText = ({ helpText }) => {
  return (
    <div className="icon-container">
      <Icon name="question circle" size="small" color="grey"/>
      <span className="tooltip-text">{ helpText }</span>
    </div>
  );
};

export default IconHelpText;