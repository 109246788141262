import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    SG_FETCH_SURVEY_STRUCTURE_COMPLIANCE,
    SG_DELETE_SURVEY_STRUCTURE_COMPLIANCE,
} from "constants/actions";

import { Icon, Header, Message } from 'semantic-ui-react'

import ComplianceForm from "./Components/Form"

const Details = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const [categoryComplianceId, setCategoryComplianceId] = useState(false)
    const [categoryComplianceDetails, setCategoryComplianceDetails] = useState(null);
    const [emailRef, setEmailRef] = useState({})

    useEffect(() => {
        setCategoryComplianceId(id)
    }, [id]);

    useEffect(() => {
        if (categoryComplianceId) {
            dispatch({
                type: SG_FETCH_SURVEY_STRUCTURE_COMPLIANCE,
                payload: { id: categoryComplianceId }
            });
        }
    }, [dispatch, categoryComplianceId]);

    const { get_category_compliance } = useSelector(
        (state) => ({
            get_category_compliance: state.surveystructurecompliance,
        }),
        shallowEqual
    );

    useEffect(() => {
        setCategoryComplianceDetails(get_category_compliance?.[categoryComplianceId])
    }, [get_category_compliance, categoryComplianceId]);

    useEffect(() => {
        setEmailRef(categoryComplianceDetails?.data)

    }, [categoryComplianceDetails]);

    if (categoryComplianceId) {
        return (
            <>
                <Container>
                    <Header as='h2'>
                        {categoryComplianceDetails?.name}
                    </Header>

                    <SectionDiv>
                        <ComplianceForm
                            row={categoryComplianceDetails}
                        />
                    </SectionDiv>
                    <SectionDiv>
                        <pre>{JSON.stringify(emailRef, null, 3)}</pre>
                    </SectionDiv>
                </Container>
            </>
        )
    } else {
        return (
            <ComplianceForm />
        )
    }
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  padding: 30px;
`