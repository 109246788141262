import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Dimmer, Loader, Grid, Pagination, Button, Header, Table } from 'semantic-ui-react'

import PageTitle, { Container, SectionDiv } from "layouts/AuthLayout/PageTitle"

import { SURVEY_TYPE, STATUS } from "data/AdminData"

import Select from "react-select";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { format } from 'date-fns'

import {
  UPDATE_SURVEY_QUESTION_PAGE_NUMBER,
  SG_GET_SURVEY_QUESTIONS,
} from "constants/actions";


const SurveyTemplateList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let ReloadPage = searchParams.get("reload");

  const [surveyTypeSelected, setSurveyTypeSelected] = useState(SURVEY_TYPE[0])
  const [statusSelected, setStatusSelected] = useState(STATUS[1])
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)

  const { get_survey_question,
    get_survey_pageNumber } = useSelector(
      (state) => ({
        get_survey_question: state.surveyquestion.survey_question,
        get_survey_pageNumber: state.surveyquestion.survey_question.pageNumber,
      }),
      shallowEqual
    );

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `${statusSelected?.value ? `&status=${statusSelected?.value}` : ''}${surveyTypeSelected?.value ? `&survey_type=${surveyTypeSelected.value}` : ''}`
    });
  }, [dispatch, page]);

  useEffect(() => {
    setResults(get_survey_question?.results);
    setTotalPages(Math.ceil(get_survey_question?.count / get_survey_question?.page_size))
    if (get_survey_question?.count % get_survey_question?.page_size) {
      setTotalPages(Math.ceil(get_survey_question?.count / get_survey_question?.page_size))
    }
  }, [get_survey_question]);

  useEffect(() => {
    setPage(get_survey_pageNumber ? get_survey_pageNumber : 1)
  }, [get_survey_pageNumber]);

  useEffect(() => {
    if (ReloadPage) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: page ? `page=${page}` : ''
      });
    }
  }, [ReloadPage, navigate, dispatch, page]);

  function handlePageChange(event, value) {
    setLoading(true)

    setPage(value.activePage);
    dispatch({
      type: UPDATE_SURVEY_QUESTION_PAGE_NUMBER,
      payload: value.activePage,
    });
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000);
    return timer
  }

  function UpdateQuestions() {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `${statusSelected?.value ? `&status=${statusSelected?.value}` : ''}${surveyTypeSelected?.value ? `&survey_type=${surveyTypeSelected.value}` : ''}`
    });
  }


  return (
    <>
      <Container>
        <PageTitle newPageTitle="Survey Templates" />

        <Grid>
          <Grid.Row>
            <Grid.Column floated="left" width={6}>
              <Header.Subheader>
                Manage survey questions and survey structure
              </Header.Subheader>
            </Grid.Column>
            <Grid.Column floated="right" width={3}>
              <Select
                name="survey_preference"
                options={SURVEY_TYPE}
                isClearable
                onChange={setSurveyTypeSelected}
                value={surveyTypeSelected}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Select
                name="survey_preference"
                options={STATUS}
                isClearable
                onChange={setStatusSelected}
                value={statusSelected}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Button onClick={UpdateQuestions}>Filter</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <SectionDiv>

        <Dimmer active={loading}>
          <Loader />
        </Dimmer>

        <Table selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Name</Table.HeaderCell>
              <Table.HeaderCell width={1}>Type</Table.HeaderCell>
              <Table.HeaderCell width={1}>Status</Table.HeaderCell>
              <Table.HeaderCell width={1}>URL Location</Table.HeaderCell>
              <Table.HeaderCell width={1}>Create On</Table.HeaderCell>
              <Table.HeaderCell width={1}>Last Updated</Table.HeaderCell>
              <Table.HeaderCell width={1}>ID</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {results && results.map((result, i) => {
              return (<Table.Row key={i} onClick={() => navigate(`/app/questions/details/${result?.id}`)} style={{ cursor: 'pointer' }}>
                <Table.Cell>{result?.name}</Table.Cell>
                <Table.Cell>{result?.survey_type && SURVEY_TYPE.filter(sy => sy.value === result?.survey_type)?.[0]?.label}</Table.Cell>
                <Table.Cell>{result?.status && STATUS.filter(sy => sy.value === result?.status)?.[0]?.label}</Table.Cell>
                <Table.Cell>{result?.url_location}</Table.Cell>
                <Table.Cell>{result?.created_at && format(new Date(result?.created_at), 'MMM. d, yyyy')}</Table.Cell>
                <Table.Cell>{result?.updated_at && format(new Date(result?.updated_at), 'MMM. d, yyyy')}</Table.Cell>
                <Table.Cell>{result?.sort_order}</Table.Cell>
              </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        <Grid>
          <Grid.Row>
            <Grid.Column floated="left" textAlign="left" width={8}>
              <Pagination
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                activePage={page}
                totalPages={totalPages ? totalPages : 0}
                onPageChange={handlePageChange}
              />
            </Grid.Column>

            <Grid.Column floated="right" width={7}>
              <Button primary
                floated='right'
                onClick={() => navigate(`/app/questions/new/`)}>
                New Survey Template
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SectionDiv>

    </>
  );
};

export default SurveyTemplateList;
