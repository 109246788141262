import React, { useState } from 'react';
import { Table, Button, Icon, Confirm } from 'semantic-ui-react';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import PageTitle, { Container } from "layouts/AuthLayout/PageTitle"

const ChecklistHistory = () => {
    const navigate = useNavigate()

    // Mock data for existing checklists
  const [checklists, setChecklists] = useState([
    {
      id: 1,
      title: 'Checklist A',
      description: 'Description of Checklist A',
      lastModified: '2023-10-01',
    },
    {
      id: 2,
      title: 'Checklist B',
      description: 'Description of Checklist B',
      lastModified: '2023-10-05',
    },
    // Add more mock checklists as needed
  ]);

  // State for confirm modal
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [checklistToDelete, setChecklistToDelete] = useState(null);

  // Mock navigation functions
  const navigateToChecklist = (checklistId = null) => {
    if (checklistId) {
      console.log(`Navigating to edit Checklist with ID: ${checklistId}`);
      navigate(`/app/recommendation-templates/details/${checklistId}`);
    } else {
      console.log('Navigating to create a new Checklist');
      // Implement navigation logic for creating a new checklist
    }
  };

  const handleDelete = (checklistId) => {
    setChecklistToDelete(checklistId);
    setConfirmOpen(true);
  };

  const confirmDelete = () => {
    setChecklists(checklists.filter((c) => c.id !== checklistToDelete));
    setConfirmOpen(false);
    setChecklistToDelete(null);
  };

  return (
    <Container>
      <PageTitle newPageTitle="Recommendation Templates" />

      <Button primary onClick={() => navigateToChecklist()} style={{ marginBottom: '20px' }}>
        <Icon name="plus" /> Create New Checklist
      </Button>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Last Modified</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {checklists.map((checklist) => (
            <Table.Row key={checklist.id}>
              <Table.Cell>{checklist.title}</Table.Cell>
              <Table.Cell>{checklist.description}</Table.Cell>
              <Table.Cell>{checklist.lastModified}</Table.Cell>
              <Table.Cell textAlign="center">
                <IconButton
                  icon
                  title="Edit Checklist"
                  onClick={() => navigateToChecklist(checklist.id)}
                >
                  <Icon name="edit" />
                </IconButton>
                <IconButton
                  icon
                  title="Delete Checklist"
                  onClick={() => handleDelete(checklist.id)}
                >
                  <Icon name="trash" color="red" />
                </IconButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={confirmDelete}
        content="Are you sure you want to delete this checklist?"
      />
    </Container>
  );
};

export default ChecklistHistory;

// Styled Components
/* const Container = styled.div`
  padding: 20px;
  max-width: 1000px;
  margin: 50px auto;
`;
 */
const Title = styled.h2`
  margin-bottom: 20px;
`;

const IconButton = styled(Button)`
  padding: 8px !important;
  background-color: transparent !important;
  box-shadow: none !important;
`;
