import React, { useState, useEffect } from "react";

import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_DEBRIEF_RESPONSES, DEBRIEF_RESPONSE_PAGE_NUMBER } from "constants/actions";

import { Dimmer, Loader, Table, Pagination, Card } from 'semantic-ui-react'

const BriefResponseList = ({ deBriefId, employeeId, empUpdateRow, setEmpUpdateRow, searchTerm }) => {
    const dispatch = useDispatch();

    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        if (deBriefId) {
            dispatch({
                type: SG_GET_DEBRIEF_RESPONSES,
                payload: `page=${page}${deBriefId ? `&debrief_schedule=${deBriefId}` : ''}${searchTerm ? `&survey_token=${searchTerm}` : ''}`,
            });
        }
        if (employeeId) {
            dispatch({
                type: SG_GET_DEBRIEF_RESPONSES,
                payload: `page=${page}${employeeId ? `&employee=${employeeId}` : ''}`,
            });
        }
        setLoading(true)
    }, [dispatch, page, deBriefId, employeeId, empUpdateRow, searchTerm]);

    const { get_debrief_responses } = useSelector(
        (state) => ({
            get_debrief_responses: state.debrief_response,
        }),
        shallowEqual
    );

    useEffect(() => {
        setResults(get_debrief_responses?.debrief_response?.results);
        setPage(get_debrief_responses?.pageNumber)
        setTotalPages(Math.ceil(get_debrief_responses?.debrief_response?.count / get_debrief_responses?.debrief_response?.page_size))
        if (get_debrief_responses?.debrief_response?.count % get_debrief_responses?.debrief_response?.page_size) {
            setTotalPages(Math.ceil(get_debrief_responses?.debrief_response?.count / get_debrief_responses?.debrief_response?.page_size))
        }
    }, [get_debrief_responses, page]);


    function handlePageChange(_event, value) {
        setPage(value.activePage);
        dispatch({
            type: DEBRIEF_RESPONSE_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    useEffect(() => {
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    return (
        <>
            <Dimmer active={loading}>
                <Loader />
            </Dimmer>
            {results?.length > 0 ? <>
                <Table selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>Completion Date</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Survey Type</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Feedback</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Employee ID</Table.HeaderCell>
                            <Table.HeaderCell width={1}>ID</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {results && results.map((result) => (
                            <RowCard row={result} key={result?.id}/>
                        ))}
                    </Table.Body>
                </Table>

                {totalPages > 1 ? <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page || 1}
                    totalPages={totalPages || 1}
                    onPageChange={handlePageChange}
                /> : <></>}
            </> : <NoRecords message={"No surveys completed"} />
            }


        </>
    );
};

export default BriefResponseList;

const NoRecords = ({ message }) => {
    return (<>
        <Card>
            <p style={{ padding: '20px' }}>{message}</p>
        </Card>
    </>)
}
