import React, { useState, useEffect } from "react";

import SearchBar from "./SearchBar";
import PageTitle, { Container, SectionDiv } from "layouts/AuthLayout/PageTitle"

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_ORGANIZATIONS, UPDATE_ORGANIZATION_PAGE_NUMBER, GET_AM_ORGANIZATION } from "constants/actions";

import { useNavigate } from "react-router-dom";

import { Dimmer, Loader, Pagination, Table } from 'semantic-ui-react'

const List = ({ setShowHeaderTxt }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        dispatch({
            type: LOAD_ORGANIZATIONS,
            payload: `page=${page}${searchTerm ? `&search=${searchTerm}` : ''}`,
        });

        setLoading(true)
    }, [dispatch, page, searchTerm]);

    const { get_organizations } = useSelector(
        (state) => ({
            get_organizations: state.organizations,
        }),
        shallowEqual
    );
    useEffect(() => {
        setShowHeaderTxt(true)
    }, [setShowHeaderTxt]);

    useEffect(() => {
        setResults(get_organizations?.organizations?.results);
        setPage(get_organizations?.pageNumber)

        const _count = get_organizations?.organizations?.count
        const _page_size = get_organizations?.organizations?.page_size

        setTotalPages(Math.ceil(_count / _page_size))
    }, [get_organizations, page]);

    function handlePageChange(_event, value) {
        setPage(value.activePage);
        dispatch({
            type: UPDATE_ORGANIZATION_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    function SelectedOrg(result) {
        dispatch({
            type: GET_AM_ORGANIZATION,
            payload: result
        });
        navigate(`/app/organizations/details/${result?.id}`)
    }

    useEffect(() => {
        const timer1 = setTimeout(() => {
            setLoading(get_organizations?.pending);
        }, 500);
        return () => clearTimeout(timer1);
    }, [get_organizations]);

    return (
        <Container>
            <PageTitle
                newPageTitle="Organizations"
                newPageSubtitle="Manage organizations"
            />

            <SectionDiv>
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </SectionDiv>

            <Dimmer active={loading}>
                <Loader />
            </Dimmer>

            <SectionDiv>
                <Table selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}>Organziation</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Time Zone</Table.HeaderCell>
                            <Table.HeaderCell width={1}>ID</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {results && results.map((result, i) => {
                            return (<Table.Row key={i} onClick={() => { SelectedOrg(result) }} style={{ cursor: 'pointer' }}>
                                <Table.Cell>{result?.name}</Table.Cell>
                                <Table.Cell>{result?.time_zone}</Table.Cell>
                                <Table.Cell>{result?.id}</Table.Cell>
                            </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>

            </SectionDiv>
            <SectionDiv>
                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page ? page : 1}
                    totalPages={totalPages || 0}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>
        </Container>
    );
};

export default List;
