import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom';

import List from './List';
import Details from './AddEdit';

import PageTitle, { Container } from "layouts/AuthLayout/PageTitle"

function AIWebChat() {

  const [reload, setReload] = useState(null)
  return (
    <Container>
      <PageTitle
        newPageTitle="ChatGPT Copilot"
        newPageSubtitle="AI web chat responses"
      />

      <Routes>
        <Route path="/" element={<List reload={reload} />} />
        <Route path="details/:id" element={<Details setReload={setReload} />} />
        {/* <Route path="create" element={<Add setReload={setReload} />} /> */}
      </Routes>
    </Container>
  );
}

export default AIWebChat;
