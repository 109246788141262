import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Icon, Table, Header, List, Card } from 'semantic-ui-react'

import { format } from 'date-fns'
//
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  PULL_EMPLOYEE,
  PULL_ORGANIZATION,
  SG_GET_EMPLOYEE_CATEGORIES,
  SET_DATA_ACNHOR
} from "constants/actions";
// 
import LoginHistory from "settings/LoginHistory/List"
import BriefResponseList from 'briefResponse/List';
import EmployeeLogList from 'employeeLog/List';
import EmployeeRecord from "employeeRecord/List"

import CategoryTags from "./CategoryTags"
import EmployeePermission from 'EmployeePermission';

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [employeeId, setEmployeeId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [employeeCategory, setEmployeeCategory] = useState(null);
  const [empUpdateRow, setEmpUpdateRow] = useState(true)

  function BackArrow() {
    navigate(-1)
  }
  useEffect(() => {
    setEmployeeId(id)

  }, [id]);

  useEffect(() => {
    if (employeeId) {
      dispatch({
        type: PULL_EMPLOYEE,
        payload: { id: employeeId }
      });
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `employee=${employeeId}`
      });
    }
  }, [dispatch, employeeId, empUpdateRow]);

  useEffect(() => {
    if (employeeDetails?.organization) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: employeeDetails?.organization }
      });
    }
  }, [dispatch, employeeDetails?.organization]);

  const { get_employees, get_employee_categories } = useSelector(
    (state) => ({
      get_employees: state.employees,
      get_employee_categories: state.employee_category.employee_category,
    }),
    shallowEqual
  );

  useEffect(() => {
    setEmployeeDetails(get_employees?.[employeeId])
    setUserId(get_employees?.[employeeId]?.account)
  }, [get_employees, employeeId]);

  useEffect(() => {
    setEmployeeCategory(null)
    if (get_employee_categories.filter(em => em.employee === Number(employeeId))) {
      setEmployeeCategory(get_employee_categories.filter(em => em.employee === Number(employeeId))?.[0]?.category)
    }

  }, [get_employee_categories, employeeId]);


  return (
    <>
        <Header as='h2' style={{ marginTop: "50px" }}>
          <Icon
            style={{ cursor: "pointer" }}
            onClick={BackArrow} name='arrow left' />
          {employeeDetails?.last_name}{", "}{employeeDetails?.first_name}
        </Header>
        <Card fluid>
          <Card.Content>
          <Container>
          <List horizontal>
                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Account Status</List.Header>
                      {employeeDetails?.status === 1 ?
                        <Icon color='green' name='check' /> :
                        <Icon color='red' name='archive' />}
                      {employeeDetails?.status_text}
                    </ListContainer>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Email</List.Header>
                      {employeeDetails?.email}
                    </ListContainer>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Email Status</List.Header>
                      {employeeDetails?.email_status_text}
                    </ListContainer>
                  </List.Content>
                </List.Item>
              </List>
              <List horizontal>
                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Created On</List.Header>
                      {employeeDetails?.created_at && format(new Date(employeeDetails?.created_at), 'MMM. d, yyyy h:mm a')}
                    </ListContainer>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Last Updated</List.Header>
                      {employeeDetails?.updated_at && format(new Date(employeeDetails?.updated_at), 'MMM. d, yyyy h:mm a')}
                    </ListContainer>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Org ID</List.Header>
                      {employeeDetails?.organization}
                    </ListContainer>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>ID</List.Header>
                      {employeeDetails?.id}
                    </ListContainer>
                  </List.Content>
                </List.Item>
              </List>
            </Container>
          </Card.Content>
        </Card>

      <SectionDiv>
        <Header as='h3'>Employee Permissions</Header>
      </SectionDiv>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Permission</Table.HeaderCell>
            <Table.HeaderCell width={2}>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Account Type</Table.Cell>
            <Table.Cell>{employeeDetails?.account_type_text}{" User"}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Can Take Surveys</Table.Cell>
            <Table.Cell>{employeeDetails?.survey_status === 1 ?
              <Icon color='green' name='check' /> :
              <Icon color='red' name='close' />}
              {employeeDetails?.survey_status === 1 ? "Yes" : "No"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Can Add Survey Users</Table.Cell>
            <Table.Cell>{employeeDetails?.survey_add_users === 1 ?
              <Icon color='green' name='check' /> :
              <Icon color='red' name='close' />}
              {employeeDetails?.survey_add_users === 1 ? "Yes" : "No"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Can Schedule Surveys</Table.Cell>
            <Table.Cell>{employeeDetails?.survey_schedule_text === "Enabled" ?
              <Icon color='green' name='check' /> :
              <Icon color='red' name='close' />}
              {employeeDetails?.survey_schedule_text === "Enabled" ? "Yes" : "No"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Can Access Data Analytics</Table.Cell>
            <Table.Cell>{employeeDetails?.access_data_analytics === 1 ?
              <Icon color='green' name='check' /> :
              <Icon color='red' name='close' />}
              {employeeDetails?.access_data_analytics === 1 ? "Yes" : "No"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Can Access Subfactor Data</Table.Cell>
            <Table.Cell>{employeeDetails?.access_subfactor_data === 1 ?
              <Icon color='green' name='check' /> :
              <Icon color='red' name='close' />}
              {employeeDetails?.access_subfactor_data === 1 ? "Yes" : "No"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Can Read Feedback</Table.Cell>
            <Table.Cell>{employeeDetails?.read_feedback === 1 ?
              <Icon color='green' name='check' /> :
              <Icon color='red' name='close' />}
              {employeeDetails?.read_feedback === 1 ? "Yes" : "No"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Portal Access</Table.Cell>
            <Table.Cell>{employeeDetails?.enable_login === true ?
              <Icon color='green' name='check' /> :
              <Icon color='red' name='close' />}
              {employeeDetails?.enable_login ? "Yes" : "No"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <SectionDiv>
        <Header as='h3'>Category Values</Header>
        {<CategoryTags rows={employeeCategory} />}
      </SectionDiv>
      <SectionDiv>
        <Header as='h3'>Employee Records</Header>
        {employeeId && <EmployeeRecord employeeId={employeeId} />}
      </SectionDiv>
      <SectionDiv>
      <Header as='h3'>Category Permissions</Header>
        {employeeId && <EmployeePermission employeeId={employeeId} />}
      </SectionDiv>
      <SectionDiv>
        <Header as='h3'>Surveys Completed</Header>
        <BriefResponseList employeeId={employeeId} empUpdateRow={empUpdateRow} setEmpUpdateRow={setEmpUpdateRow} />
      </SectionDiv>
      <SectionDiv>
        <Header as='h3'>Login History</Header>
       { <LoginHistory userId={userId} />}
      </SectionDiv>
      <SectionDiv>
        <Header as='h3'>Employee Log</Header>
        <EmployeeLogList employeeId={employeeId} />
      </SectionDiv>

    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`
const Container = styled.div`
  display: flex;
  margin: 10px 0;
`