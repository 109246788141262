import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Button, Input } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid';
import { ColorPicker, stringToHexColor } from 'categories/Components/ColorPicker'

export function AddRow({
    AddToRow, setChanged,
    selectedRow, EditUpdateRow, setOpenCategoryEdit }) {
    const [id, setId] = useState(uuidv4)
    const [name, setName] = useState('')
    const [french, setFrench] = useState('')
    const [description, setDescription] = useState('')
    const [color, setColor] = useState('#BCBCBC')

    function AddToList() {
        if (selectedRow?.id) {
            EditUpdateRow({
                id,
                name,
                fr: french,
                description,
                color
            })
        } else {
            AddToRow(
                {
                    id,
                    name,
                    fr: french,
                    description,
                    color
                }
            )
        }
        setOpenCategoryEdit(false)
/*         Reset()
 */    }

    /*     function Reset() {
            setChanged(Math.random())
            setId(uuidv4)
            setName('')
            setFrench('')
            setDescription('')
            setColor('#BCBCBC')
        }
     */
    useEffect(() => {
        if (selectedRow?.id) {
            setId(selectedRow?.id)
            setName(selectedRow?.name)
            setFrench(selectedRow?.fr)
            setDescription(selectedRow?.description)
            setColor(selectedRow?.color)
        }
    }, [selectedRow]);

    function _handleKeyDown(e) {
        // if (e.key === 'Enter') {
        //     // console.log('do validate');
        //     AddToRow(
        //         {
        //             id,
        //             name,
        //             fr: french,
        //             description,
        //             color
        //         }
        //     )
        //     Reset()
        // }
    }

    function ChangeTextCol(e) {
        setName(e.target.value)
        setColor(stringToHexColor(e.target.value, 50, 60))
    }

    function ChangeFrenchCol(e) {
        setFrench(e.target.value)
        // setColor(stringToHexColor(e.target.value, 50, 60))
    }

    function ChangeDescriptionCol(e) {
        setDescription(e.target.value)
        // setColor(stringToHexColor(e.target.value, 50, 60))
    }

    return (
        <>
            <label>UUID</label>
            <RowLine>
                <StyledInput
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                />
                <Button onClick={() => setId(uuidv4)}>
                    New UUID
                </Button>
            </RowLine>

            <label>Heading</label>
            <SectionDiv>
                <StyledInput
                    fluid
                    type='text'
                    placeholder='Option Heading'
                    name="options"
                    value={name}
                    onChange={ChangeTextCol}
                    onKeyDown={_handleKeyDown} />

            </SectionDiv>

            <label>French</label>
            <SectionDiv>
                <StyledInput
                    fluid
                    type='text'
                    name="options"
                    value={french}
                    placeholder='Option Heading French'
                    onChange={ChangeFrenchCol}
                    onKeyDown={_handleKeyDown} />

            </SectionDiv>
            <label>Description</label>

            <SectionDiv>
                <StyledInput
                    fluid
                    type='text'
                    name="options"
                    value={description}
                    placeholder='Description'
                    onChange={ChangeDescriptionCol}
                    onKeyDown={_handleKeyDown} />
            </SectionDiv>

            <SectionDiv>
                <ColorPicker
                    color={color}
                    setColor={(c) => setColor(c.hex)}
                />
            </SectionDiv>

            <RowInputLine>
                <Button primary onClick={AddToList}>
                    {selectedRow?.id ? "Update Category Option" : "Add Category Option"}
                </Button>
            </RowInputLine>
        </>

    )
};



const SectionDiv = styled.div`
                padding: 5px;
                margin: 5px;
                display: flex;
                flex-direction: column;
                `

const StyledInput = styled(Input)`
                min-width: 600px;
                font-size: 110%;
                margin: 0 10px;
                display: inline-block;
                `

const RowInputLine = styled.span`
                min-width: 600px;
                font-size: 110%;
                margin: 10px;
                display: inline-block;
                display: flex;
                `
const RowLine = styled.span`
               
                font-size: 110%;
                margin: 0px 20px;
                display: inline-block;
                display: flex;
                `

const RowData = styled.div`
                display: flex;
                flex-direction: row;
                `