import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Message, Icon, Grid, Header } from "semantic-ui-react";

import { BasicErrorMessage } from "utilities/ErrorMessages"
import Upload from "./Upload";
import List from "./List";
import Details from "./Details";

import { saveAs } from "file-saver";

import OrganizationSelect from "organizations/OrganizationSelect";
import PageTitle, { Container, SectionDiv } from "layouts/AuthLayout/PageTitle"

import {
	LOAD_TEAMS,
	PULL_ORGANIZATION,
	SG_GET_FILE_UPLOADS,
	SG_GET_SURVEY_STRUCTURES,
	SG_GET_ORGANIZATION_SELECT,
	CLEAR_ERRORS,
} from "constants/actions";


const AllPageOptions = [
	{
		id: 1,
		name: "Upload Employees",
	},
	{
		id: 2,
		name: "Employee Import Details",
	},
	{
		id: 3,
		name: "Upload an Employee List",
	},
	{
		id: 4,
		name: "Preview Upload",
	},
];

const FileUploads = () => {
	const dispatch = useDispatch();

	const [organizationId, setOrganizationId] = useState(false)
	const [showReportId, setShowReportId] = useState(false);
	const [showErrors, setShowErrors] = React.useState(false);
	const [fetchAgain, setFetchAgain] = useState(0);
	const [deletedRecord, setDeletedRecord] = useState(0);
	const [createEdit, setCreateEdit] = useState(0);
	const [showPage, setShowPage] = useState(AllPageOptions[0]);
	const [surveyStructure, setSurveyStructure] = useState(false)
	const [resetPageLoad, setResetPageLoad] = useState(false)

	const [loading, setLoading] = useState(false)

	const EmployeeId = useSelector(
		(state) => Number(state.auth?.employee_id),
		shallowEqual
	);

	const {
		get_employee,
		get_survey_structure,
		get_selectedOrg,
		get_errors,
		get_file_uploads,
	} = useSelector(
		(state) => ({
			get_errors: state.errors,
			get_auth: state.auth,
			get_employee: state.employees,
			get_selectedOrg: state.selectedOrg,
			get_survey_structure: state.surveystructure.survey_structure,
			get_file_uploads: state.fileupload,
		}),
		shallowEqual
	);

	useEffect(() => {
		setOrganizationId(get_selectedOrg?.organization?.id)
	}, [get_selectedOrg]);

	useEffect(() => {
		if (organizationId) {
			dispatch({
				type: SG_GET_ORGANIZATION_SELECT
			})
			dispatch({
				type: LOAD_TEAMS,
				payload: `organization=${organizationId}`,
			});
			dispatch({
				type: PULL_ORGANIZATION,
				payload: { id: organizationId },
			});
			dispatch({
				type: SG_GET_SURVEY_STRUCTURES,
				payload: `organization=${organizationId}`,
			});
			return;
		}
	}, [dispatch, organizationId]);

	useEffect(() => {
		if (organizationId) {
			dispatch({
				type: SG_GET_FILE_UPLOADS,
				payload: `organization=${organizationId}`,
			});
		}

	}, [dispatch, organizationId, loading]);

	useEffect(() => {
		const timer1 = setTimeout(() => {
			setLoading(resetPageLoad)
		}, 2000)
		return () => clearTimeout(timer1)
	}, [resetPageLoad]);

	useEffect(() => {
		setSurveyStructure(get_survey_structure?.results?.[0])
	}, [get_survey_structure]);

	useEffect(() => {
		if (get_employee[EmployeeId]?.account_type === 5) {
			setCreateEdit(true);
			return;
		}

		if (get_employee[EmployeeId]?.role < 7) {
			setCreateEdit(true);
			return;
		}
		setCreateEdit(false);
	}, [get_employee, EmployeeId]);

	useEffect(() => {
		if (get_errors?.status === 403) {
			setShowErrors(get_errors?.data?.detail);
		}
	}, [get_errors]);

	function clearErrors() {
		dispatch({
			type: CLEAR_ERRORS,
		});
		setShowErrors(false);
	}

	const DemoFile = () => {
		saveAs(surveyStructure?.csv_template);
	};

	function BackToHomePage() {
		setShowPage(AllPageOptions[1])
		// setResetPageLoad(Math.random())
	}

	return (
		<Container>
			{showErrors && (
				<Message
					negative
					onDismiss={clearErrors}
					header="There was an error!"
					content={showErrors}
				/>
			)}
			<BasicErrorMessage />

			<PageTitle newPageTitle={showPage?.name} />

			<SectionDiv>
				<Grid>
					<Grid.Row>
						<Grid.Column floated="left" width={7}>
							<Header.Subheader>
								Upload new employees for an organization.
							</Header.Subheader>
						</Grid.Column>

						<Grid.Column width={4}>
							<OrganizationSelect setResetPageLoad={setResetPageLoad} />
						</Grid.Column>

						{organizationId ?
							<Grid.Column width={5}>
								<Buttons>
									<StyledButtonGrey onClick={() => DemoFile()}>
										<Icon name="download" />
										Download Sample
									</StyledButtonGrey>

									<StyledButton onClick={() => setShowPage(AllPageOptions[2])}>
										<Icon name="upload" />
										Upload File
									</StyledButton>
								</Buttons>
							</Grid.Column> : <></>
						}
					</Grid.Row>
				</Grid>
			</SectionDiv>

			{organizationId ?
				<>
					{showPage?.id !== 1 && (
						<Back onClick={BackToHomePage}>
							<Icon name="chevron left" />
							Back
						</Back>
					)}

					<>
						{showPage?.id === 3 && organizationId && (
							<Container>
								<Upload
									setFetchAgain={setFetchAgain}
									setShowReportId={setShowReportId}
									setResetPageLoad={setResetPageLoad}
									organizationId={organizationId}
									setShowPage={setShowPage}
									AllPageOptions={AllPageOptions}
									BackToHomePage={BackToHomePage}
								/>
							</Container>
						)}

						{showPage?.id === 2 ? (
							showReportId ? (
								<Details
									showReportId={showReportId}
									setShowReportId={setShowReportId}
									setFetchAgain={setFetchAgain}
									setDeletedRecord={setDeletedRecord}
									organizationId={organizationId}
									setShowPage={setShowPage}
									AllPageOptions={AllPageOptions}
									get_survey_structure={get_survey_structure}
								/>
							) : (
								<p>Not a Relevant Upload File Selected</p>
							)
						) : (
							""
						)}

						{showPage?.id === 1 && (
							<>
								{!surveyStructure?.csv_template ?
									<Message
										color="red"
										icon="warning sign"
										header="No Survey Structure File Found"
										content={`We were unable to find a matching survey configuration for this organzation. This
											information needs to be configured for the organization before employees can be uploaded.
											`}
									/>
									:
									<>
										<Container>
											<List
												get_file_uploads={get_file_uploads}
												resetPageLoad={resetPageLoad}
												setResetPageLoad={setResetPageLoad}
												fetchAgain={fetchAgain}
												setFetchAgain={setFetchAgain}
												showErrors={showErrors}
												deletedRecord={deletedRecord}
												createEdit={createEdit}
												setDeletedRecord={setDeletedRecord}
												setShowReportId={setShowReportId}
												setShowPage={setShowPage}
												AllPageOptions={AllPageOptions}
											/>
										</Container>
									</>
								}
							</>
						)}
					</>
				</> :
				<Message
					color="orange"
					icon="warning sign"
					header="No Organization Selected"
					content={`Please select an organization to view the list of files`}
				/>
			}
		</Container>
	);
};

export default FileUploads;

const StyledButton = styled.button`
	color: white;
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: #2d50e2;
	border: none;
	outline: none;
	padding: 0px 30px;
	border: 1px solid white;

	@media (max-width: 768px) {
		height: 30px;
		font-size: 12px;
		padding: 0 10px 0 10px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #2d50e2;
		color: #2d50e2;
	}
`;

const StyledButtonGrey = styled.button`
	color: white;
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: #595959;
	border: none;
	outline: none;
	padding: 0px 30px;
	border: 1px solid white;

	@media (max-width: 768px) {
		height: 30px;
		font-size: 12px;
		padding: 0 10px 0 10px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #595959;
		color: #595959;
	}
`;

const Buttons = styled.div`
	width: 100%;
	display: flex;
`;

const Back = styled.div`
	font-family: "Barlow", sans-serif;
	color: #595959;
	margin-top: 30px;
	font-size: 16px;
	cursor: pointer;
`;
