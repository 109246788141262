import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from 'WebImage/List';
import CreateImage from 'WebImage/ImageAdd';

import PageTitle, { Container } from "layouts/AuthLayout/PageTitle"

function WebImage() {

  return (
    <Container>
      <PageTitle 
        newPageTitle="Web Images" 
        newPageSubtitle="Manage custom organization image files"
      />

      <Routes>
        <Route path="/" element={<List />} />
        <Route path="edit/:id" element={<CreateImage />} />
        <Route path="upload" element={<CreateImage />} />
      </Routes>
    </Container>
  );
}

export default WebImage;
